import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Snackbar,
  Typography
} from '@material-ui/core'
import React, { MouseEventHandler } from 'react'
import RemoteControlAuthorize from './RemoteControlAuthorize';
import * as keycloak from '../keycloak';
import DialogProgress from '../shared/DialogProgress';
import ComputerIcon from '@material-ui/icons/Computer';
import CloseIcon from '@material-ui/icons/Close'
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => {
  return ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    headerCloseButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.info.main
    },
    dialogHeaderIcon: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "3rem"
    },
    dialogActions: {
      justifyContent: "space-between",
      margin: "15px 5px 5px"
    }
  })
})

export const DialogHeader = ({ onClose }: { onClose?: MouseEventHandler }) => {
  const classes = useStyles()
  return (
    <DialogTitle>
      <Box display='flex' justifyContent='center' gridGap='15px' color='#00B0FF'>
        <Box display='flex' flexDirection='column' justifyContent='center' fontSize='3rem'>
          <ComputerIcon fontSize='inherit' />
        </Box>

        <div>
          <Typography variant='h4'>Enter your authorization code</Typography>
          <Typography variant='subtitle1' color='textPrimary'>Enter the authorization code you see on your box here.</Typography>
        </div>
      </Box>

      {onClose &&
        <IconButton
          aria-label={'close'}
          onClick={onClose}
          className={classes.headerCloseButton}>
          <CloseIcon />
        </IconButton>
      }

    </DialogTitle>
  )
}

function RemoteControlDialog({
  open = false,
  onSuccess = () => { },
  setOpen = (type: null) => { }
}) {
  const classes = useStyles();
  const handleCloseDialog = () => setOpen(null)
  const [loading, setLoading] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [error, setError] = React.useState('')
  const handleAuthorize = () => {
    console.log('handleAuthorize', code)
    if (code.length < 6) return
    setLoading(true)
    fetch(process.env.REACT_APP_IOT_API_URL + '/authorization/confirm', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + keycloak.getToken()
      },
      body: JSON.stringify({ code }),
    })
      .then(response => {
        if (response.status === 201) {
          handleCloseDialog()
          onSuccess()
        }
        else return response.text()
      })
      .then(text => {
        if (text) {
          const json = JSON.parse(text)
          if (json.error) {
            setError(json.error)
          }
        }
      })
      .finally(() => setLoading(false))
  }
  const submitRef = React.createRef<HTMLInputElement>()
  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      open={open}
      PaperProps={{
        style: {
          backgroundColor: '#fff'
        }
      }}
    >

      <DialogProgress open={loading} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        data-cy='dialogSnackbar'
        open={!!error}
      >
        <Alert severity='error'>
          <Typography>
            {error}
          </Typography>
        </Alert>
      </Snackbar>
      <DialogHeader onClose={handleCloseDialog} />
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          // do not await, or form validation is borked
          handleAuthorize()
        }}
      >
        <input type="submit" ref={submitRef} style={{ display: 'none' }} />
        <DialogContent>
          <Box display={"flex"} justifyContent="center">
            <RemoteControlAuthorize
              onCancel={handleCloseDialog}
              onCode={setCode}
            />
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleCloseDialog}
          >Cancel</Button>
          <Button
            data-cy='authorize'
            onClick={() => submitRef.current?.click()}
            variant='contained'
            color='primary'
          >Authorize</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RemoteControlDialog