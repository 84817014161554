import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import AppRoot from './AppRoot';
import { useDarkTheme } from './useDarkTheme';

export default function ThemeWrapper() {
  const darkTheme = useDarkTheme();

  return ( 
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AppRoot />
    </ThemeProvider>
  );
}
