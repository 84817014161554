import * as keycloak from '../keycloak';

export function searchForUsers(search: string) {
  return fetch(`https://sso-test.chronotrack.com/admin/realms/ccs/users?search=` + encodeURIComponent(search), {
    headers: {
      Authorization: 'Bearer ' + keycloak.getToken()
    }
  })
    .then(response => {
      if (response.status === 200) {
        return response.text();
      }
      throw new Error(response.statusText);
    })
    .then(text => {
      const json = JSON.parse(text);
      return json;
    });
}
