import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { themeFactory } from './themeFactory';

export function useDarkTheme() {

  // TODO: React.effect for useMediaQuery('(prefers-color-scheme: dark)')
  const darkMode = useSelector<{ darkMode: boolean; }, boolean>(state => state.darkMode);
  const darkTheme = useMemo(
    () => themeFactory(darkMode),
    [darkMode]
  );
  return darkTheme;
}
