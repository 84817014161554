import { createMuiTheme } from '@material-ui/core'

export function themeFactory(darkMode = false) {
  return createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        contrastText: 'rgb(74, 74, 74)',
        main: '#fff'
      }
    },
    typography: {
      h6: {
        color: 'rgb(249, 122, 45)',
        textTransform: 'uppercase'/*  */
      },
      h5: {
        color: 'rgb(74, 74, 74)',
        textTransform: 'uppercase'
      },
      h4: {
        color: '#00B0FF',
        textTransform: 'none',
        fontWeight: 'bold'
      },
      subtitle1: {
        color: 'rgb(74, 74, 74)',
        textTransform: 'none'
      }
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundColor: '#00B0FF',
          color: '#FFF',
          '&:hover': {
            backgroundColor: '#00B0FF'
          }
        },
        outlinedPrimary: {
          color: '#00B0FF'
        }
      },
      MuiLink: {
        root: {
          color: '#00B0FF'
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#fff'
        }
      }
    },
  })
}
