import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: 'darkMode',
  initialState: false,
  reducers: {
    toggle: state => !state
  }
})

export const toggleDarkMode = slice.actions.toggle

export default slice.reducer
